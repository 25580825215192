.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 8px;
    margin-bottom: 10px;
    width: 100%;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #75b5dc;
    outline: 0;
    font-size: 16px;
    color: #00529b;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    font-weight: 500;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown~.form__label {
    font-size: 14px;
    cursor: text;
    top: 25px;
}

.form__field.textarea:placeholder-shown~.form__label {
    top: 75px;
}

.form__field.textarea {
    height: 90px;
    resize: none;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 500;
}

.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #75b5dc, #085398);
    border-image-slice: 1;
}

.form__field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #047bc3;
    font-weight: 700;
}

.form__field.textarea:focus~.form__label {
    top: 0px;
}

.form__field:required,
.form__field:invalid {
    box-shadow: none;
}

.form__field:disabled {
    border-bottom: 2px solid #8f9cba;
}

.password_error {
    padding: 0px 28px;
    margin-top: -5px;
}

.password_error p {
    margin-bottom: 0px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #ff4d4d;
    font-weight: 800;
}

.uploadfile-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.upload-input {
    width: 50px;
    position: relative;
    cursor: pointer;
}

.upload-input input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    font-size: 23px;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
}

.file-cont {
    text-align: center;
    background: rgba(102, 194, 255, 1);
    color: #ffffff;
    font-weight: 900;
    cursor: pointer;
    font-size: 25px;
    padding: 2px 5px;
}

.upload-filename {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    min-width: 300px;
    max-width: 300px;
    overflow: hidden;
    padding: 2px 6px;
    max-height: 43px;
    min-height: 43px;
}

.file__label {
    font-size: 15px;
    color: #047bc3;
    font-weight: 700;
}

@media (max-width: 768px) {
    .password_error {
        padding: 0px 15px;
    }

    .mob-50-col>div {
        width: 50%;
    }
}