.fullformdetails {
    padding: 15px 120px;
    background-color: #ffffff;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
}

.fulldetailstable {
    padding: 15px 50px;
    background-color: #ffffff;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
}

.table-heading {
    width: 100%;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
    text-align: center;
    font-weight: 600;
    color: #4da6ff;
}

.no-data {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #ff6666 !important;
    border-bottom: 2.5px solid #f2f2f2;
}

.loading-gif {
    width: 100%;
    text-align: center;
    font-size: 38px;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    color: #00b386;
    font-weight: 600;
}

.my-table i {
    padding: 4px 6px;
    cursor: pointer;
    font-size: 17px;
}

.my-table .fa-ban {
    color: rgba(255, 128, 128, 1);
}

.my-table .fa-edit {
    color: rgba(51, 187, 255, 1);
}

.my-table .fa-trash {
    color: rgba(255, 128, 128, 1);
}

.my-table .fa-eye {
    color: rgba(0, 179, 134, 1);
}

.my-table .fa-comment-dots {
    color: #6699ff;
    font-size: 18px;
}

.data-visit {
    border: 2px solid #e6e6e6;
    padding: 15px 30px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
}

.data-cont {
    width: 100%;
    padding: 2px 5px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 2px;
}

.data-title {
    width: 100%;
    position: relative;
    color: #4d4d4d;
    font-weight: 700;
    margin-left: -4px;
    font-size: 17px;
}

.data-visit-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.data-visit.drafts {
    border-color: rgba(65, 163, 226, 255);
}

.data-visit.drafts .data-visit-title {
    color: rgba(65, 163, 226, 255);
}

.data-visit.progresse {
    border-color: rgba(255, 173, 83, 255);
}

.data-visit.progresse .data-visit-title {
    color: rgba(255, 173, 83, 255);
}

.data-visit.scheduleds {
    border-color: rgba(128, 128, 255, 255);
}

.data-visit.scheduleds .data-visit-title {
    color: rgba(128, 128, 255, 255);
}

.data-visit.completeds {
    border-color: rgba(65, 184, 126, 255);
}

.data-visit.completeds .data-visit-title {
    color: rgba(65, 184, 126, 255);
}

.data-visit.cancelleds {
    border-color: rgba(254, 119, 134, 255);
}

.data-visit.cancelleds .data-visit-title {
    color: rgba(254, 119, 134, 255);
}

.icon-td {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.text-center{
    text-align: center;
}
@media (max-width: 768px) {
    .fulldetailstable{
        padding: 5px 12px;
        overflow-x: hidden;
    }
    .my-table{
        overflow-x: scroll;
        font-size: 13px;
    }
    .button-container{
        margin-top: 8px;
    }
    .fullformdetails{
        padding: 5px 12px;
        overflow-x: hidden;
    }
    .my-table td{
        padding: 5px !important;
    }
    .my-table th{
        padding: 5px !important;
        vertical-align: middle;
    }
    .my-table i{
        padding: 3px;
    }
    .data-visit{
        padding: 5px 15px;
    }
}