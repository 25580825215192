.fullpofile-image {
    position: relative;
    width: 250px;
    height: auto;
    text-align: center;
    padding: 10px;
    float: left;
}

.fullpofile-image img {
    position: relative;
    width: 205px;
    height: 205px;
    padding: 5px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 20px;
}

.profile-details {
    flex: 0 0 auto;
    padding: 15px;
    font-size: 17px;
    font-weight: 300;
    font-family: "Roboto_Condensed", sans-serif;
    letter-spacing: 1px;
    margin-top: 10px;
    width: calc(100% - 250px);
}

.profile-item {
    margin-left: 10px;
    margin-bottom: 2px;
    color: #404040;
}

.profile-item>label {
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    color: #262626;
}

.profile-title {
    text-align: center;
    width: 100%;
    font-size: 33px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    color: #7474f6;
    border-top: 2.5px solid #f2f2f2;
    border-bottom: 2.5px solid #f2f2f2;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.no-contact-data {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #ff6666;
    padding: 10px;
}

.profilecontact-card {
    margin: 6px;
    border-radius: 10px;
    flex: 0 0 auto;
}

.profilecontact-type {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    border-radius: 5px 5px 0px 0px;
    color: #ffffff !important;
}

.profilecontact-regular>.profilecontact-type {
    border-bottom: 2px solid rgb(0, 230, 172);
    background-color: rgb(0, 230, 172);
}

.profilecontact-emergency>.profilecontact-type {
    border-bottom: 2px solid rgb(255, 128, 128);
    background-color: rgb(255, 128, 128);
}

.profilecontact-emergency {
    border: 2px solid rgb(255, 128, 128);
}

.profilecontact-regular {
    border: 2px solid rgb(0, 230, 172);
}

.profilecontact-phone {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1px;
    padding: 2px 10px;
}

.profilecontact-email {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 10px;
}

.profilecontact-whatsapp {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 2px 10px;
}

.profilecontact-whatsapp>i {
    color: #00cc99;
}

.profilecontact-phone>i {
    color: #ffa366;
}

.profilecontact-email>i {
    color: #b3b3ff;
}

.profilecontact-card i {
    margin-right: 4px;
    font-weight: 600;
    font-size: 15px;
}

.profilecontact-card .label {
    width: 100%;
    font-size: 12px;
    margin-bottom: -5px;
    padding-left: 5px;
    color: #666666;
}

.profile-backgound {
    background-color: #f2f2f2;
}

.addcontactfield .col-md-3 {
    padding: 5px 8px;
}

.addcontactfield {
    border-top: 2.5px solid #f2f2f2;
    margin-top: 8px;
}

.button-right {
    text-align: right;
}

.contact-con .profilecontact-type {
    text-align: left !important;
}

.profilecontact-type>i {
    padding: 0px 6px;
    cursor: pointer;
}

.profilecontact-type>.head-text {
    text-align: center;
    width: calc(100% - 61px);
    display: inline-block;
}

@media (max-width: 768px) {
    .fullpofile-image {
        width: 100%;
    }
    .profile-details{
        width: 100%;
    }    
}