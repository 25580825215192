.coming_soon {
    text-align: center;
    font-family: 'Dosis', sans-serif;
    font-size: 89px;
    font-weight: 900;
    width: 50%;
    margin:auto;
}
.countbox {
    font-size: 42px;
    word-spacing: 18px;
}