.total-body{
    position: absolute;
    min-height: 500px;
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
a {
    text-decoration: none;
}


