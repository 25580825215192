body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.myRow {
  display: flex;
  flex-wrap: wrap;
}
.myRow>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}
a{
  text-decoration: none !important;
  color: inherit;
}
/*Background Color*/
.bg_lightgray{
  background-color: #f2f2f2;
}
/*Text Color*/
.colorgray{
  color: #f2f2f2;
}
/*loading gif*/
.grayscreenload{
  background-color: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50;
}
.loadinggif{
  position:absolute;
	left:50%;
	top:50%;
	transform:translateX(-50%) translateY(-50%);
}
.loadinggif img{
	width:170px;
	height:auto;
}