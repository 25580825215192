.input-formtitle {
  width: 100%;
  text-align: center;
  padding: 8px;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 18px;
  font-family: 'Poppins', sans-serif;
  color: #4da6ff;
}


.input-formgroup {
  width: 100%;
  padding: 6px 20px;
  position: relative;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.input-label {
  width: 100%;
  position: relative;
  color: #4d4d4d;
  margin-bottom: 3px;
  font-weight: 700;
  padding-left: 6px;
}

.input-cell {
  width: 100%;
  padding: 6px 10px;
  position: relative;
  outline: none;
  border: 2.5px solid #e6e6e6;
  background-color: #ffffff;
  border-radius: 8px;
}

.input-cell:focus {
  outline: none;
  border: 2.5px solid #1a8cff;
}

textarea.input-cell {
  min-height: 140px;
}

/*Slider*/
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #00994d;
}

input:focus+.slider {
  box-shadow: 0 0 1px #00994d;
}

input:checked+.slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.slider.round {
  border-radius: 23px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 768px) {
  .input-formgroup {
    padding: 3px 8px;
  }
  .input-formtitle{
    font-size: 23.5px;
    padding: 5px;
  }
  .mob-but>div{
    width: 50%;    
  }
  .mob-but{
    margin-bottom: 10px;
    padding: 10px;
    align-items: center;
    text-align: center;
  }
}